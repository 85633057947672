<template>
  <span class="calendar-key">
    <strong v-if="index === 0">{{ i18n['calendar-key'].tcEventKey }}:</strong>
    <span class="event-key" :style="{color: color}">
      <span class="color-block" :style="{backgroundColor: color}"></span>
      {{ obj.type }}
    </span>
  </span>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'calendar-key': {
            tcEventKey: 'Event Key'
          }
        }
      }
    },
    obj: Object,
    index: Number
  },
  data() {
    return {
      color: this.obj.color
    }
  },
}
</script>
<style lang="scss">
.calendar-key {
  display: flex;
  strong {
    margin-right: 15px;
    display: inline-block;
  }
  .event-key {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 14px;
    line-height: 19px;

    .color-block {
      display: block;
      width: 17px;
      height: 17px;
      margin-right: 8px;
    }
  }
}
</style>
