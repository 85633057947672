/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
export const Loader = {
  methods: {
    showLoader() {
      this.isLoading = this.$loading.show()
    },
    closeLoader() {
      if (this.isLoading != null) {
        this.isLoading.hide()
      }
    },
    showErrorMessage(text, confirmButtonText, retryFunctionCallback) {
      this.closeLoader()
      this.$swal({
        icon: 'error',
        text: text,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: confirmButtonText
      }).then(result => {
        if (result.value) {
          retryFunctionCallback(this.user.user.org_key)
        }
      })
    },
    message(text = '', icon = 'error', confirmButtonText = 'Ok') {
      this.$swal({
        icon: icon,
        text: text === '' ? 'Oops!. Internet connection lost' : text,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: confirmButtonText
      })
    }
  },
  computed: {
    ...mapState(['user'])
  },
  data() {
    return {
      isLoading: null,
      _memberType: null
    }
  }
}
