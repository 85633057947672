<template>
  <div
    class="calendar-toolbar"
    :class="{
      'switch-grid': switch_toggle == 'grid',
      'switch-month': switch_toggle == 'month',
    }"
  >
    <div class="start-end-app d-flex" v-if="switch_toggle == 'month'">
      <b-container>
        <div id="dpContainer" class="dp-container">
          <div id="dpStart" class="dp-startend">
            <b-form-datepicker
              id="startDate"
              v-model="searchDateRange.startDate"
              class="mb-2 contact_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :locale="prefCulture"
              :label-help="i18n['calendar-toolbar'].tcCalHelp"
              :label-no-date-selected="i18n['calendar-toolbar'].tcNoDateSelected"
              :placeholder="i18n['calendar-toolbar'].tcDatePickerStartPlaceholder"
              :show-decade-nav="showDecadeNav"
              :hide-header="hideHeader"
              size="sm"
            >
              <template #button-content style="padding: 12px">
                <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
              </template>
            </b-form-datepicker>
          </div>
          <div id="dpEnd" class="dp-startend">
            <b-form-datepicker
              id="endDate"
              v-model="searchDateRange.endDate"
              class="mb-2 contact_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :locale="prefCulture"
              :label-help="i18n['calendar-toolbar'].tcCalHelp"
              :label-no-date-selected="i18n['calendar-toolbar'].tcNoDateSelected"
              :placeholder="i18n['calendar-toolbar'].tcDatePickerEndPlaceholder"
              :show-decade-nav="showDecadeNav"
              :hide-header="hideHeader"
              size="sm"
            >
              <template #button-content style="padding: 12px">
                <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
              </template>
            </b-form-datepicker>
          </div>
        </div>
      </b-container>
    </div>
    <div class="member-type">
      <strong>{{ i18n['calendar-toolbar'].tcMemberType }}:</strong>
      <form class="filters d-flex justify-content-start justify-content-sm-end">
        <b-form-select
          v-model="selected"
          :plain="true"
          :options="options"
          class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0"
        >
          <template slot="first">
            <option :value="null" disabled>{{ i18n['calendar-toolbar'].tcAll }}</option>
          </template>
        </b-form-select>
      </form>
    </div>
    <div>
      <Gbutton
        v-if="iCanSee(view_calendar_event_controls.calendar_add_event_button)"
        class="add-event btn-tertiary btn-w-sm"
        @click.native="addEvent"
      >
        {{ i18n['calendar-toolbar'].tcAddEvent }}
      </Gbutton>
    </div>
    <div class="row">
      <Gbutton
        v-if="show_switch"
        class="grid btn-outline btn-w-sm col ml-3"
        @click.native="toggleView('month')"
        :class="{ 'btn-primary': switch_toggle == 'month' }"
      >
        {{ i18n['calendar-toolbar'].tcGrid }}
      </Gbutton>
      <Gbutton
        v-if="show_switch"
        class="month btn-outline btn-w-sm col"
        @click.native="toggleView('grid')"
        :class="{ 'btn-primary': switch_toggle == 'grid' }"
      >
        {{ i18n['calendar-toolbar'].tcMonth }}
      </Gbutton>
      <Gbutton
        v-if="show_switch && switch_toggle === 'month'"
        class="today btn-tertiary btn-w-sm"
        @click.native="today"
      >
        {{ i18n['calendar-toolbar'].tcToday }}
      </Gbutton>
    </div>
    <div class="member-type" v-if="!show_switch">
      <strong v-if="stateMode">{{ i18n['calendar-toolbar'].tcStateRegion }}:</strong>
      <strong v-else>{{ i18n['calendar-toolbar'].tcCountryRegion }}:</strong>
      <form class="filters d-flex justify-content-start justify-content-sm-end">
        <b-form-select
          v-model="stateRegion"
          :plain="true"
          :options="optionsStateRegion"
          class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0"
          @change="toggleArea"
        >
          <template slot="first">
            <option :value="'all'">
              {{ i18n['calendar-toolbar'].tcAll }}
            </option>
          </template>
        </b-form-select>
      </form>
    </div>

    <div class="member-type" v-if="!show_switch">
      <strong v-if="stateMode">{{ i18n['calendar-toolbar'].tcStateArea }}:</strong>
      <strong v-else>{{ i18n['calendar-toolbar'].tcCountryArea }}:</strong>
      <form class="filters d-flex justify-content-start justify-content-sm-end">
        <b-form-select
          v-model="stateArea"
          :plain="true"
          :options="optionsStateArea"
          class="church-select mr-0 mr-sm-2 mb-3 mb-sm-0"
          @change="selectedArea"
        >
          <template slot="first">
            <option :value="'all'" selected>{{ i18n['calendar-toolbar'].tcAll }}</option>
          </template>
        </b-form-select>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import BackArrow from '@/assets/svgs/back-arrow.vue'
import calendarService from '../../../services/calendarService'
import Gbutton from '@/components/Gbutton.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iDown from '@/assets/svgs/icon-down-arrow.vue'
import { Loader } from '@/mixins/Loader'

export default {
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'calendar-toolbar': {
            tcAddEvent: 'Add Event',
            tcAll: 'All',
            tcAuxiliary: 'Auxiliary',
            tcBoth: 'Both',
            tcCalHelp: 'Use cursor keys to navigate calendar dates',
            tcDatePickerStartPlaceholder: 'Start date',
            tcDatePickerEndPlaceholder: 'End date',
            tcGideon: '',
            tcGrid: 'Grid',
            tcMemberType: 'Member Type',
            tcMonth: 'Month',
            tcNoDateSelected: 'No date selected',
            tcReset: 'Reset',
            tcToday: 'Today',
            tcStateArea: 'State Area',
            tcStateRegion: 'State Region',
          },
        }
      },
    },
    switch_toggle: String,
    show_switch: {
      type: Boolean,
      default: true,
    },
    stateMode: Boolean,
    translationsAll: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      allLoaded: { options: false, translations: false }, // see comments in 'watch' section
      view_calendar_event_controls: {
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166',
      },
      tempOptionsStateArea: [],
      stateArea: 'all',
      stateRegion: 'all',
      optionsStateRegion: [],
      optionsStateArea: [],
      searchDateRange: { startDate: null, endDate: null },
      currentMonthYear: null,
      currentMonthIndex: null,
      currentYear: null,
      selected: null,
      istoday: false,
      options: [
        {
          value: 'all',
          text: 'All',
        },
        {
          value: 'gideon',
          text: 'Gideon',
        },
        {
          value: 'auxiliary',
          text: 'Auxiliary',
        },
        {
          value: 'both',
          text: 'Both',
        },
      ],
    }
  },
  mixins: [Loader],
  async created() {
    if (!this.$props.show_switch) {
      await this.getOrgAreas(
        this.user.demograph.state_key,
        this.user.demograph.camp_key,
        this.user.demograph.language_key
      )
    }
  },
  methods: {
    ...mapActions({
      getAreas: 'eventCalendar/getAreas',
    }),
    setDefaultArea() {
      //default to area that users camp belongs to
      for (let index = 0; index < this.tempOptionsStateArea.length; index++) {
        let selectedArea = this.tempOptionsStateArea[index].camps.filter((x) =>
          x.toLowerCase().includes(this.user.demograph.camp_key)
        )[0]
        if (!!selectedArea) {
          this.stateArea = this.tempOptionsStateArea[index].value
          this.$emit('changestatearea', {
            value: this.tempOptionsStateArea[index].value,
            text: this.tempOptionsStateArea[index].text,
          })
        }
      }
    },
    toggleArea(value) {
      this.disableStateArea = false
      if (value === 'all') {
        this.optionsStateArea = this.tempOptionsStateArea
        this.stateArea = 'all'
        this.$emit('changestateregion', { value: 'all', text: 'all' })
        return
      } else {
        this.optionsStateArea = this.tempOptionsStateArea.filter((x) => x.org_key === value)
      }
      this.stateArea = 'all'
      let obj = this.optionsStateRegion.filter((x) => x.value === value)[0]
      this.$emit('changestateregion', { value: obj.value, text: obj.text })
    },
    selectedArea(value) {
      if (value === 'all') {
        this.$emit('changestatearea', { value: 'all', text: 'all' })
        return
      }
      let obj = this.tempOptionsStateArea.filter((x) => x.value === value)[0]
      this.$emit('changestatearea', { value: obj.value, text: obj.text })
    },
    addEvent() {
      this.$router.push({ path: '/calendar/event-select/select-add-event' })
    },
    async getOrgAreas(t_parent_org_key, org_key, lng_key) {
      this.showLoader()
      await calendarService
        .GetOrganisationHierarchy(t_parent_org_key, org_key, lng_key)
        .then((res) => {
          this.optionsStateRegion = res.data.sub_areas.map((item) => {
            return { text: item.org_name, value: item.t_org_key }
          })
          let combinevalues = []
          res.data.sub_areas.map((item) => {
            combinevalues = [...combinevalues, ...item.sub_areas]
          })

          this.optionsStateRegion.sort((a, b) => {
            var textA = a.text.toUpperCase()
            var textB = b.text.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
          })

          this.optionsStateArea = combinevalues.map((item) => {
            return {
              text: item.org_name,
              value: item.org_key,
              org_key: item.region_key,
              camps: item.camps,
            }
          })
          this.optionsStateArea.sort((a, b) => {
            var textA = a.text.toUpperCase()
            var textB = b.text.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
          })
          this.getAreas(this.optionsStateArea).then((res) => {})
          this.tempOptionsStateArea = this.optionsStateArea

          this.allLoaded.options = true
        })
        .catch((err) => {
          console.error('Error', err)
        })
        .finally(() => {
          this.closeLoader()
        })
    },
    removeSpaces(givenString) {
      if (!givenString || givenString instanceof String || givenString.length < 1) return ''
      const searchVal = /[^a-z0-9]/gi // alphanumerics only, remove all spaces and special chars
      return givenString.replace(searchVal, '')
    },
    translateStateAreasAndRegions() {
      const translations = this.translationsAll
      if (!translations || !translations.tcAllZones) return false

      this.optionsStateRegion = this.optionsStateRegion.map((region) => {
        const lookup = 'tc' + this.removeSpaces(region.text) // IE, `tcStateRegionCentral`
        const translatedText = translations.hasOwnProperty(lookup) ? translations[lookup] : region.text
        return { ...region, text: translatedText }
      })

      this.optionsStateArea = this.optionsStateArea.map((area) => {
        if (area.text.includes('State Area')) {
          const areaNum = area.text.split('State Area')[1].trim()
          const stateArea = translations.tcStateArea || 'State Area'
          return { ...area, text: `${stateArea} ${areaNum}` }
        } else return area
      })
    },
    toggleView(val) {
      this.$emit('toggleView', val)
    },
    today() {
      this.istoday = !this.istoday
      this.$emit('today', this.istoday)
      if (this.istoday) {
        this.todayInText = this.i18n['calendar-toolbar'].tcReset
      } else {
        this.todayInText = this.i18n['calendar-toolbar'].tcToday
      }
    },
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
    }),
    ...mapState(['user']),
  },
  watch: {
    allLoaded: {
      // We need both options list to be fully populated and the translations to come in via props. We can't
      //  guarantee which order this will happen in. So, we deep watch this object which tracks the status of
      //  each of the items we need. The keys `options` and `translations` are flipped to true when they are complete
      deep: true,
      handler() {
        if (this.allLoaded.options && this.allLoaded.translations) {
          this.translateStateAreasAndRegions()
          this.allLoaded.options = false
          // `false` because if the Camp Selector is updated, we need to reload the list, but not the translations
        }
      },
    },
    i18n: function (newVal) {
      const { tcAll, tcAuxiliary, tcBoth, tcGideon } = newVal['calendar-toolbar']
      this.options[0].text = tcAll
      this.options[1].text = tcGideon
      this.options[2].text = tcAuxiliary
      this.options[3].text = tcBoth
    },
    selected: function (newval, oldval) {
      this.$emit('filterby', {
        type: 'member',
        value: newval,
      })
    },
    searchDateRange: {
      handler: function (newval, oldval) {
        if (newval !== null && newval.startDate !== null && newval.endDate !== null) {
          this.$emit('filterby', {
            type: 'daterange',
            value: newval,
          })
        }
      },
      deep: true,
    },
    translationsAll: function (newVal) {
      if (newVal && newVal.tcAllZones) {
        this.allLoaded.translations = true
      }
    },
  },
  components: {
    BackArrow: BackArrow,
    Gbutton: Gbutton,
    iCalendar: iCalendar,
    iDown: iDown,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
.dp-container {
  display: flex;
  .dp-startend {
    width: 50%;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
.contact_date {
  width: 100%;
  @include breakpoint(sm) {
    width: 100%;
  }
}

.calendar-toolbar {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 30px;
  @include breakpoint(sm) {
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
  }
  .month-label {
    font-family: $DIN;
    font-size: 30px;
    color: #000;
    letter-spacing: 1;
    line-height: 44px;
    margin-left: 45px;
    margin-right: 45px;
  }
  .prev-arrow,
  .next-arrow {
    svg {
      height: 24px;
      width: 14px;
      path {
        fill: #000;
      }
    }
  }
  .switch-month {
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(sm) {
      margin-bottom: 35px;
    }
  }
  .next-arrow svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
  .member-type {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #636363;
    text-transform: uppercase;
    margin-left: 40px;
    @include breakpoint(sm) {
      margin-left: 0;
      margin-bottom: 20px;
      width: 100%;
      strong {
        flex: 1 1 auto;
      }
    }
    form {
      margin-left: 35px;
      @include breakpoint(sm) {
        margin-left: 15px;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        max-width: 50%;
      }
      select {
        background-color: #fff;
        margin-right: 15px !important;
        @include breakpoint(sm) {
          width: 100% !important;
          max-width: 100% !important;
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .add-event {
    margin-right: 15px;
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  .today {
    margin-right: 40px;
    @include breakpoint(sm) {
      width: 33.333%;
      margin-right: 0;
      border: 1px solid #003946;
      color: #003946;
      font-weight: normal;
      text-transform: none;
      background-color: #fff;
      border-right: none;
    }
  }
  .grid,
  .month {
    font-weight: normal;
    text-transform: none;
    @include breakpoint(sm) {
      width: 33.333%;
      margin-right: 0;
    }
    &.btn-primary {
      color: #fff;
      font-weight: 800;
      text-transform: uppercase;
    }
  }
  .start-end-app {
    width: 48%;
    margin-bottom: 20px;
    .start-d-button,
    .end-d-button {
      align-items: center;
      font-weight: bold;
    }
    .start-d-button {
      border-right: none;
      span {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .end-d-button {
      position: relative;
      &:before {
        content: '';
        height: 23px;
        width: 1px;
        display: block;
        background-color: #003946;
        position: absolute;
        left: 0;
      }
      border-left: none;
      svg {
        margin-left: 15px;
      }
    }
    .apply-d-button {
      margin-left: 10px;
    }
  }
  &.switch-month {
    flex-wrap: wrap;
    .today {
      order: 8;
      margin-left: 15px;
    }
    > div,
    > button {
      margin-bottom: 20px;
    }
    .member-type {
      margin-left: 155px;
    }
    @include breakpoint(sm) {
      .member-type {
        order: 1;
        margin-left: 0;
        strong {
          margin-right: 15px;
          flex: 0 0 auto;
        }
        form {
          max-width: 100%;
        }
      }
      .add-event {
        order: 2;
      }
      .today {
        order: 3;
        margin-left: 0;
      }
      .grid {
        order: 4;
      }
      .month {
        order: 5;
      }
      .start-end-app {
        order: 7;
        width: 100%;
        .start-d-button,
        .end-d-button {
          flex: 1 1 auto;
          justify-content: center;
        }
        .apply-d-button {
          display: none !important;
        }
      }
    }
  }
}
</style>
